<template>
  <div id="identity">
    <div class="page960">
      <div class="section">
        <div>
          <div class="header-identity">Download</div>
          <a
            href="https://nvx.co.id/docs/NVX_KYC_User_Manual_Book.pdf"
            download="KYC User Manual Book"
            target="_blank"
          >
            <button class="btn-download download-kyc-doc" type="button">KYC Manual</button>
          </a>
          <a
            href="https://nvx.co.id/docs/NVX_KYC_Tutorial.mp4"
            download="KYC User Manual Video"
            target="_blank"
          >
            <button class="btn-download download-kyc-vid" type="button">KYC Video Guide</button>
          </a>
        </div>
        <div class="header-identity">
          {{ $t('account.identity.header_verification') }}
        </div>
        <div class="description" v-html="$t('kyc.description')"></div>
        <div class="form-identity-container">
          <div id="identityForm" :class="{ disabled: record.status === 'pending' }">
            <div class="group-iden" v-if="isIndoIden && isCorporate !== 'true'">
              <div class="identity-form__country-select">
                <template v-if="previousCountry === 'Indonesia'">
                  <template v-if="record.status === 'rejected'">
                    <div class="digisign-status">
                      <div class="wrap-status">
                        <span class="status rejected">{{ $t('account.btn_rejected') }}</span>
                        <div class="reason-error mobile">
                          <span
                            class="invalid-reason"
                            v-html="handleDigisignError(record.error_digisign)"
                          ></span>
                        </div>
                      </div>
                      <span>{{ $t('kyc.note_rejected') }}</span>
                      <div class="country-select-logo">
                        <img src="@/assets/images/digisign.jpg" alt="digisign" />
                      </div>
                    </div>
                    <div class="reason-error desktop">
                      <!-- <strong>{{ $t('kyc.reason') }} </strong> -->
                      <span
                        class="invalid-reason"
                        v-html="handleDigisignError(record.error_digisign)"
                      ></span>
                    </div>
                  </template>
                  <template v-else-if="record.status === 'pending'">
                    <div class="digisign-status">
                      <span class="status pending">{{ $t('account.btn_pending') }}</span>
                      <div>
                        <div>{{ $t('kyc.note_pending') }}</div>
                      </div>
                      <div class="country-select-logo">
                        <img src="@/assets/images/digisign.jpg" alt="digisign" />
                      </div>
                    </div>
                    <div class="group-tooltip">
                      <span class="icon-help">
                        <div class="box-tooltip">
                          <div class="bubble top" id="still-not-receive">
                            <div>{{ $t('funds.withdrawals.tooltip') }}</div>
                            <a @click="openPopupContactUs()">{{ $t('menu_bottom.contact_us') }}</a>
                          </div>
                        </div>
                      </span>
                      <span>{{ $t('funds.withdrawals.did_not_receive_the_mail') }}</span>
                    </div>
                  </template>
                </template>
                <div class="identity-form-group">
                  <div class="identity-form-label">
                    {{ $t('account.identity.country') }}
                    <span class="required">*</span>
                  </div>
                  <div class="identity-form-input toolbar-element">
                    <select-box
                      v-model="record.country"
                      tabindex="1"
                      :options="countries"
                      :propertyOption="'name'"
                      isSearchCountry
                    />
                  </div>
                </div>
              </div>
              <div
                v-if="!record.status || record.status === 'pending' || record.status === 'rejected'"
                class="kyc-not-submit"
              >
                <div class="kyc-notice mt" v-html="$t('kyc.notice_indonesian')"></div>
              </div>
              <div class="identity-col-4">
                <div class="identity-form-group identity-form-group-0">
                  <div class="identity-form-label">
                    {{ $t('account.identity.fullname') }}
                    <span class="required">*</span>
                  </div>
                  <div class="input-group">
                    <div class="custom-input">
                      <div class="form-input" :class="{ 'input-error': errors.has('fullName') }">
                        <input
                          tabindex="2"
                          type="text"
                          name="fullName"
                          v-model="record.fullName"
                          maxlength="128"
                          @focus="resetErrors"
                        />
                      </div>
                    </div>

                    <div class="error-box">
                      <span v-show="errors.has('fullName')" class="invalid-feedback">
                        {{ errors.first('fullName') }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="identity-form-group identity-form-group-1">
                  <div class="identity-form-label">
                    {{ $t('account.identity.sex') }}
                    <span class="required">*</span>
                  </div>
                  <div class="identity-form-input toolbar-element">
                    <select-box
                      v-model="record.gender"
                      tabindex="3"
                      :options="genderList"
                      :propertyOption="'value'"
                    />
                  </div>
                  <div class="error-box">
                    <span v-show="errors.has('gender')" class="invalid-feedback">{{
                      errors.first('gender')
                    }}</span>
                  </div>
                </div>
                <div class="identity-form-group identity-form-group-1">
                  <div class="identity-form-label">
                    {{ $t('account.identity.dob') }}
                    <span class="required">*</span>
                  </div>
                  <div
                    class="identity-form-input toolbar-element identity-form-input--icon-suffix"
                    @click="resetErrors"
                  >
                    <DatePicker
                      tabindex="4"
                      :language="datePickerLang"
                      :placeholder="$t('account.identity.dob_placeholder')"
                      v-model="record.dob"
                      class="date-picker-input"
                      format="dd/MM/yyyy"
                      :disabled-dates="disabledDates"
                      :class="{ 'input-error': errors.has('date_of_birth') }"
                    >
                    </DatePicker>
                  </div>
                  <div class="error-box">
                    <span v-show="errors.has('date_of_birth')" class="invalid-feedback">{{
                      errors.first('date_of_birth')
                    }}</span>
                  </div>
                </div>
                <div class="identity-form-group identity-form-group-1">
                  <div class="identity-form-label">
                    {{ $t('account.identity.passport') }}
                    <span class="required">*</span>
                  </div>
                  <div class="input-group">
                    <div class="custom-input">
                      <div
                        class="form-input"
                        :class="{
                          'input-error': errors.has('id_number'),
                        }"
                      >
                        <input
                          type="text"
                          maxlength="30"
                          name="id_number"
                          v-model="record.passportID"
                          tabindex="5"
                          @focus="resetErrors"
                        />
                      </div>
                    </div>

                    <div class="error-box">
                      <span v-show="errors.has('id_number')" class="invalid-feedback">
                        {{ errors.first('id_number') }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="identity-col-2">
                <div class="identity-form-group identity-form-group-0">
                  <div class="identity-form-label">
                    {{ $t('account.identity.address') }}
                    <span class="required">*</span>
                  </div>
                  <div class="input-group">
                    <div class="custom-input">
                      <div class="form-input" :class="{ 'input-error': errors.has('address') }">
                        <input
                          type="text"
                          name="address"
                          v-model="record.address"
                          maxlength="500"
                          tabindex="6"
                          @focus="resetErrors"
                          :placeholder="$t('account.identity.address_placeholder')"
                        />
                      </div>
                    </div>

                    <div class="error-box">
                      <span v-show="errors.has('address')" class="invalid-feedback">
                        {{ errors.first('address') }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="identity-col-2" style="margin-top: 0;" >
                  <div class="identity-form-group identity-form-group-0">
                    <div class="identity-form-label">
                      {{ $t('account.identity.postal_code') }}
                      <span class="required">*</span>
                    </div>
                    <div class="input-group">
                      <div class="custom-input">
                        <div class="form-input" :class="{ 'input-error': errors.has('postal_code') }">
                          <input
                            type="text"
                            name="postal_code"
                            v-model="record.postal_code"
                            maxlength="10"
                            tabindex="7"
                            @focus="resetErrors"
                          />
                        </div>
                      </div>
  
                      <div class="error-box">
                        <span v-show="errors.has('postal_code')" class="invalid-feedback">
                          {{ errors.first('postal_code') }}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div class="identity-form-group identity-form-group-0">
                    <div class="identity-form-label">
                      NPWP Number
                      <span class="required">*</span>
                    </div>
                    <div class="input-group">
                      <div class="custom-input">
                        <div class="form-input" :class="{ 'input-error': errors.has('npwp') }">
                          <input
                            type="text"
                            name="npwp"
                            v-model="record.npwp"
                            tabindex="8"
                            @focus="resetErrors"
                            maxlength="15"
                          />
                        </div>
                      </div>
  
                      <div class="error-box">
                        <span v-show="errors.has('npwp')" class="invalid-feedback">
                          {{ errors.first('npwp') }}
                        </span>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <div class="identity-col-4">
                <div class="identity-form-group identity-form-group-0">
                  <div class="identity-form-label">
                   Occupation
                    <span class="required">*</span>
                  </div>
                  <div class="input-group">
                    <div class="custom-input">
                      <div class="form-input" :class="{ 'input-error': errors.has('job') }">
                        <input
                          tabindex="9"
                          type="text"
                          name="job"
                          v-model="record.job"
                          maxlength="128"
                          @focus="resetErrors"
                        />
                      </div>
                    </div>

                    <div class="error-box">
                      <span v-show="errors.has('job')" class="invalid-feedback">
                        {{ errors.first('job') }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="identity-form-group identity-form-group-0">
                  <div class="identity-form-label">
                    Average Annual Income
                    <span class="required">*</span>
                  </div>
                  <div class="input-group">
                    <div class="custom-input">
                      <div class="form-input" :class="{ 'input-error': errors.has('income') }">
                        <input
                          tabindex="10"
                          type="text"
                          name="income"
                          v-model="record.income"
                          @focus="resetErrors"
                        />
                      </div>
                    </div>

                    <div class="error-box">
                      <span v-show="errors.has('income')" class="invalid-feedback">
                        {{ errors.first('income') }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="identity-form-group identity-form-group-0">
                  <div class="identity-form-label">
                    Source Of Funds
                    <span class="required">*</span>
                  </div>
                  <div class="input-group">
                    <div class="custom-input">
                      <div class="form-input" :class="{ 'input-error': errors.has('source_of_funds') }">
                        <input
                          tabindex="11"
                          type="text"
                          name="source_of_funds"
                          v-model="record.source_of_funds"
                          maxlength="128"
                          @focus="resetErrors"
                        />
                      </div>
                    </div>

                    <div class="error-box">
                      <span v-show="errors.has('source_of_funds')" class="invalid-feedback">
                        {{ errors.first('source_of_funds') }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="identity-form-group identity-form-group-0">
                  <div class="identity-form-label">
                     Account Creation Purpose
                    <span class="required">*</span>
                  </div>
                  <div class="input-group">
                    <div class="custom-input">
                      <div class="form-input" :class="{ 'input-error': errors.has('account_purpose') }">
                        <input
                          tabindex="12"
                          type="text"
                          name="account_purpose"
                          v-model="record.account_purpose"
                          maxlength="128"
                          @focus="resetErrors"
                        />
                      </div>
                    </div>

                    <div class="error-box">
                      <span v-show="errors.has('account_purpose')" class="invalid-feedback">
                        {{ errors.first('account_purpose') }}
                      </span>
                    </div>
                  </div>
                </div>
               
              </div>
            </div>
            <div class="group_iden" v-else>
              <div class="group_input">
                <div class="identity-form-group identity-form-group-0">
                  <div class="identity-form-label">
                    {{ $t('account.identity.country') }}
                    <span class="required">*</span>
                  </div>
                  <div class="identity-form-input toolbar-element">
                    <select-box
                      tabindex="11"
                      v-model="record.country"
                      :options="countries"
                      :propertyOption="'name'"
                      isSearchCountry
                    />
                  </div>
                </div>
                <div class="identity-form-group identity-form-group-0">
                  <div class="identity-form-label">
                    {{ $t('account.identity.fullname') }}
                    <span class="required">*</span>
                  </div>
                  <div class="input-group">
                    <div class="custom-input">
                      <div class="form-input" :class="{ 'input-error': errors.has('fullName') }">
                        <input
                          type="text"
                          name="fullName"
                          v-model="record.fullName"
                          maxlength="190"
                          tabindex="12"
                          @focus="resetErrors"
                        />
                      </div>
                    </div>

                    <div class="error-box">
                      <span v-show="errors.has('fullName')" class="invalid-feedback">
                        {{ errors.first('fullName') }}
                      </span>
                    </div>
                  </div>
                </div>

                <div class="identity-form-group identity-form-group-1">
                  <div class="identity-form-label">
                    {{ $t('account.identity.sex') }}
                    <span class="required">*</span>
                  </div>

                  <div class="identity-form-input toolbar-element">
                    <select-box
                      v-model="record.gender"
                      tabindex="13"
                      :options="genderList"
                      :propertyOption="'value'"
                    />
                  </div>
                  <div class="error-box">
                    <span v-show="errors.has('gender')" class="invalid-feedback">{{
                      errors.first('gender')
                    }}</span>
                  </div>
                </div>

                <div class="identity-form-group identity-form-group-1">
                  <div class="identity-form-label">
                    {{ $t('account.identity.dob') }}
                    <span class="required">*</span>
                  </div>
                  <div
                    class="identity-form-input toolbar-element identity-form-input--icon-suffix"
                    @click="resetErrors"
                  >
                    <DatePicker
                      tabindex="14"
                      :language="datePickerLang"
                      :placeholder="$t('account.identity.dob_placeholder')"
                      v-model="record.dob"
                      class="date-picker-input"
                      format="dd/MM/yyyy"
                      :disabled-dates="disabledDates"
                      :class="{ 'input-error': errors.has('date_of_birth') }"
                    >
                    </DatePicker>
                  </div>
                  <div class="error-box">
                    <span v-show="errors.has('date_of_birth')" class="invalid-feedback">{{
                      errors.first('date_of_birth')
                    }}</span>
                  </div>
                </div>

                <div class="identity-form-group identity-form-group-1">
                  <div v-if="isIndoIden" class="identity-form-label">
                    NIK Number
                    <span class="required">*</span>
                  </div>
                  <div v-else class="identity-form-label">
                    {{ $t('account.identity.passport_wna') }}
                    <span class="required">*</span>
                  </div>
                  <div class="input-group">
                    <div class="custom-input">
                      <div
                        class="form-input"
                        :class="{
                          'input-error': errors.has('id_number'),
                        }"
                      >
                        <input
                          type="text"
                          maxlength="30"
                          name="passport"
                          v-model="record.passportID"
                          tabindex="15"
                          @focus="resetErrors"
                        />
                      </div>
                    </div>

                    <div class="error-box">
                      <span v-show="errors.has('id_number')" class="invalid-feedback">
                        {{ errors.first('id_number') }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="identity-col-2">
                <div class="identity-form-group identity-form-group-0">
                  <div class="identity-form-label">
                    {{ $t('account.identity.address') }}
                    <span class="required">*</span>
                  </div>
                  <div class="input-group">
                    <div class="custom-input">
                      <div class="form-input" :class="{ 'input-error': errors.has('address') }">
                        <input
                          type="text"
                          name="address"
                          v-model="record.address"
                          maxlength="500"
                          tabindex="16"
                          @focus="resetErrors"
                          :placeholder="$t('account.identity.address_placeholder')"
                        />
                      </div>
                    </div>

                    <div class="error-box">
                      <span v-show="errors.has('address')" class="invalid-feedback">
                        {{ errors.first('address') }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="identity-form-group identity-form-group-0">
                  <div class="identity-form-label">
                    {{ $t('account.identity.postal_code') }}
                    <span class="required">*</span>
                  </div>
                  <div class="input-group">
                    <div class="custom-input">
                      <div class="form-input" :class="{ 'input-error': errors.has('postal_code') }">
                        <input
                          type="text"
                          name="postal_code"
                          v-model="record.postal_code"
                          maxlength="10"
                          tabindex="17"
                          @focus="resetErrors"
                        />
                      </div>
                    </div>

                    <div class="error-box">
                      <span v-show="errors.has('postal_code')" class="invalid-feedback">
                        {{ errors.first('postal_code') }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="kyc-notice">
                <strong>{{ $t('kyc.notice_under16') }}</strong>
                <ul>
                  <li>{{ $t('kyc.notice_under17') }}</li>
                </ul>
              </div>
            </div>

            <!-- ID Card (Front) -->
            <div class="group_iden">
              <div class="left_content_iden left_content_iden_title">
                {{
                  isIndoIden
                    ? $t('account.identity.identity_cover')
                    : $t('account.identity.identity_cover_wna')
                }}
                <span class="required">*</span>
              </div>
              <div class="group-info">
                <div class="mid_iden" ref="uploadWrapper">
                  <div class="box">
                    <div
                      class="box_add_img_iden preView"
                      :class="{ 'image-error': errors.has('id_front') }"
                    >
                      <img
                        @click="
                          removeImg(
                            $refs.file,
                            $refs.imageFront,
                            $refs.uploadWrapper,
                            'showHandleButton1',
                            'front'
                          )
                        "
                        src="/images/icon/icon-close.svg"
                        alt="close-icon"
                        class="close-icon"
                      />
                      <div class="img-wrapper">
                        <img
                          class="preview preview_0"
                          :src="`${STORAGE_URL}/${record.srcFront}`"
                          ref="imageFront"
                          :class="{ 'input-error': errors.has('id_front') }"
                          v-if="record.srcFront"
                        />
                        <template v-else>
                          <img
                            v-show="showHandleButton1"
                            class="preview preview_0"
                            ref="imageFront"
                            :class="{ 'input-error': errors.has('id_front') }"
                          />
                          <div
                            v-show="!showHandleButton1"
                            class="preview preview_0 fake-img"
                            :class="{ 'input-error': errors.has('id_front') }"
                          ></div>
                        </template>
                        <div class="upload-wrapper">
                          <!-- Remove Icon -->
                          <img
                            class="cloud-upload"
                            src="/images/cloud-upload.svg"
                            alt="cloud-upload"
                          />
                          <!-- Upload Button -->
                          <div class="button_add_file_iden" tabindex="105">
                            {{ $t('account.identity.choose_file') }}
                            <input
                              type="file"
                              @change="
                                (event) =>
                                  previewImage(
                                    event,
                                    $refs.imageFront,
                                    1,
                                    'showHandleButton1',
                                    'uploadWrapper'
                                  )
                              "
                              ref="file"
                              accept="image/png,image/jpeg,image/jpg,image/heic,image/heif"
                              :title="titleInput"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-show="errors.has('id_front')" class="invalid-feedback">
                      {{ errors.first('id_front') }}
                    </div>
                  </div>
                  <div class="description">
                    <p>{{ $t('account.identity.image_identity') }}</p>
                  </div>
                </div>
                <div class="right_iden">
                  <div class="example mgb10">
                    <img width="230" src="/images/passport_front.png" />
                  </div>
                  <div class="example-description">
                    {{ $t('account.identity.example') }}
                  </div>
                </div>
              </div>
            </div>
            <div class="group_iden">
              <div class="left_content_iden left_content_iden_title">
                {{
                  isIndoIden
                    ? $t('account.identity.identity_back')
                    : $t('account.identity.identity_back_wna')
                }}
                <span class="required">*</span>
              </div>
              <div class="group-info">
                <div class="mid_iden" ref="uploadWrapper2">
                  <div class="box">
                    <div
                      class="box_add_img_iden preView"
                      :class="{ 'image-error': errors.has('id_back') }"
                    >
                      <img
                        @click="
                          removeImg(
                            $refs.file1,
                            $refs.imageBack,
                            $refs.uploadWrapper2,
                            'showHandleButton2',
                            'back'
                          )
                        "
                        src="/images/icon/icon-close.svg"
                        alt="close-icon"
                        class="close-icon"
                      />
                      <div class="img-wrapper">
                        <img
                          class="preview preview_0"
                          :src="`${STORAGE_URL}/${record.srcBack}`"
                          ref="imageBack"
                          :class="{ 'input-error': errors.has('id_back') }"
                          v-if="record.srcBack"
                        />
                        <template v-else>
                          <img
                            v-show="showHandleButton2"
                            class="preview preview_0"
                            ref="imageBack"
                            :class="{ 'input-error': errors.has('id_back') }"
                          />
                          <div
                            v-show="!showHandleButton2"
                            class="preview preview_0 fake-img"
                            :class="{ 'input-error': errors.has('id_back') }"
                          ></div>
                        </template>
                        <div class="upload-wrapper">
                          <img
                            class="cloud-upload"
                            src="/images/cloud-upload.svg"
                            alt="cloud-upload"
                          />
                          <div class="button_add_file_iden" tabindex="106">
                            {{ $t('account.identity.choose_file') }}
                            <input
                              type="file"
                              @change="
                                (event) =>
                                  previewImage(
                                    event,
                                    $refs.imageBack,
                                    2,
                                    'showHandleButton2',
                                    'uploadWrapper2'
                                  )
                              "
                              ref="file1"
                              accept="image/png,image/jpeg,image/jpg,image/heic,image/heif"
                              :title="titleInput1"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-show="errors.has('id_back')" class="invalid-feedback">
                      {{ errors.first('id_back') }}
                    </div>
                  </div>
                  <div class="description">
                    <p>{{ $t('account.identity.identity_image_back') }}</p>
                  </div>
                </div>
                <div class="right_iden">
                  <div class="example mgb10">
                    <img class="w_100_max_1000" src="/images/passport_back.jpg" />
                  </div>
                  <div class="example-description">
                    {{ $t('account.identity.example') }}
                  </div>
                </div>
              </div>
            </div>
            <div class="group_iden" id="id_selfie">
              <div class="left_content_iden left_content_iden_title">
                <div class="title_selfie">
                  {{ $t('account.identity.selfie') }}
                  <span class="required">*</span>
                </div>
              </div>
              <div class="group-info">
                <div class="mid_iden" ref="uploadWrapper3">
                  <div class="box">
                    <div
                      class="box_add_img_iden preView"
                      :class="{ 'image-error': errors.has('id_selfie') }"
                    >
                      <img
                        @click="
                          removeImg(
                            $refs.file2,
                            $refs.imageSelfie,
                            $refs.uploadWrapper3,
                            'showHandleButton3',
                            'selfie'
                          )
                        "
                        src="/images/icon/icon-close.svg"
                        alt="close-icon"
                        class="close-icon"
                      />
                      <div class="img-wrapper">
                        <img
                          class="preview preview_0"
                          :src="`${STORAGE_URL}/${record.srcSelfie}`"
                          ref="imageSelfie"
                          :class="{ 'input-error': errors.has('id_selfie') }"
                          v-show="record.srcSelfie"
                        />
                        <div v-show="!record.srcSelfie">
                          <img
                            v-show="showHandleButton3"
                            class="preview preview_0"
                            ref="imageSelfie2"
                            :class="{ 'input-error': errors.has('id_selfie') }"
                          />
                          <div
                            v-show="!showHandleButton3"
                            class="preview preview_0 fake-img"
                            :class="{ 'input-error': errors.has('id_selfie') }"
                          ></div>
                        </div>
                        <div class="upload-wrapper">
                          <img class="cloud-upload" src="/images/camera.svg" alt="camera" />
                          <div
                            class="button_add_file_iden"
                            tabindex="107"
                            @click="toggleTakePhoto(true)"
                          >
                            {{
                              $refs.file2?.files[0]
                                ? $t('account.identity.retake_selfie_photo')
                                : $t('account.identity.take_selfie_photo')
                            }}
                            <input
                              type="file"
                              @change="
                                (event) =>
                                  previewImage(
                                    event,
                                    $refs.imageSelfie,
                                    3,
                                    'showHandleButton3',
                                    'uploadWrapper3'
                                  )
                              "
                              accept="image/png,image/jpeg,image/jpg"
                              ref="file2"
                              :title="titleInput2"
                              v-show="false"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-show="errors.has('id_selfie')" class="invalid-feedback">
                      {{ errors.first('id_selfie') }}
                    </div>
                  </div>
                  <div class="description">
                    <p>
                      {{
                        $t('account.identity.text_selfie', {
                          APP_NAME: app_name,
                        })
                      }}
                    </p>
                    <p>{{ $t('account.identity.image_passport_data') }}</p>
                    <p class="required">
                      <span>*</span>{{ $t('account.identity.text_condition1') }}
                    </p>
                  </div>
                </div>
                <div class="right_iden">
                  <div class="example mgb10" id="selfie">
                    <img
                      v-if="checkShowByLocale() == 'ja'"
                      class="w_100_max_1000"
                      src="/images/hold_passport_ja.png"
                    />
                    <img
                      v-else-if="checkShowByLocale() == 'zh'"
                      class="w_100_max_1000"
                      src="/images/hold_passport_zh.png"
                    />
                    <img
                      v-else-if="checkShowByLocale() == 'ko'"
                      class="w_100_max_1000"
                      src="/images/hold_passport_ko.png"
                    />
                    <img
                      v-else-if="checkShowByLocale() == 'en'"
                      class="w_100_max_1000"
                      src="/images/selfie.svg"
                    />
                  </div>
                  <div class="example-description">
                    {{ $t('account.identity.example') }}
                  </div>
                </div>
              </div>
            </div>
            <div class="group_iden" id="video_selfie">
              <div class="left_content_iden left_content_iden_title">
                <div class="title_selfie">
                  {{ $t('account.identity.take_a_video_and_note') }}
                  <span class="required">*</span>
                </div>
              </div>
              <div class="group-info">
                <div class="mid_iden" ref="uploadWrapper4">
                  <div class="box">
                    <div
                      class="box_add_img_iden preView"
                      :class="{ 'image-error': errors.has('video_selfie') }"
                    >
                      <img
                        src="/images/icon/icon-close.svg"
                        alt="close-icon"
                        @click="removeVideo"
                        class="close-icon"
                        v-if="record.status !== 'pending'"
                      />
                      <div class="img-wrapper">
                        <div class="video-wrapper" v-show="videoSrc">
                          <video width="100%" height="100%" controls :src="videoSrc">
                            {{ $t('account.identity.not_support_video') }}
                          </video>
                          <div tabindex="20" class="button_add_file_iden" @click="handleRetake">
                            {{ $t('account.identity.retake_video') }}
                            <input type="file" accept="video/*" ref="file3" v-show="false" />
                          </div>
                        </div>
                        <template v-if="!videoSrc">
                          <img
                            class="preview preview_0"
                            :class="{
                              'input-error': errors.has('video_selfie'),
                            }"
                          />
                          <div class="upload-wrapper">
                            <img class="cloud-upload" src="/images/camera.svg" alt="camera" />
                            <div tabindex="108" class="button_add_file_iden" @click="handleRetake">
                              {{ $t('account.identity.liveness_checking') }}
                            </div>
                          </div>
                        </template>
                      </div>
                    </div>
                    <div v-show="errors.has('video_selfie')" class="invalid-feedback">
                      {{ errors.first('video_selfie') }}
                    </div>
                  </div>
                  <div class="description">
                    <div v-html="$t('account.identity.video_note')"></div>
                    <p class="required">
                      <span>*</span>{{ $t('account.identity.text_condition2') }}
                    </p>
                  </div>
                </div>
                <div class="right_iden">
                  <div class="example mgb10" id="selfie">
                    <img
                      v-if="checkShowByLocale() == 'ja'"
                      class="w_100_max_1000"
                      src="/images/hold_passport_ja.png"
                    />
                    <img
                      v-else-if="checkShowByLocale() == 'zh'"
                      class="w_100_max_1000"
                      src="/images/hold_passport_zh.png"
                    />
                    <img
                      v-else-if="checkShowByLocale() == 'ko'"
                      class="w_100_max_1000"
                      src="/images/hold_passport_ko.png"
                    />
                    <img
                      v-else-if="checkShowByLocale() == 'en'"
                      class="w_100_max_1000"
                      src="/images/selfie.svg"
                    />
                  </div>
                  <div class="example-description">
                    {{ $t('account.identity.example') }}
                  </div>
                </div>
              </div>
            </div>
            <div class="group_iden">
              <button
                class="btn-submit"
                :class="{ chrome_only: isChrome }"
                @click="saveIdentity"
                tabindex="108"
                :disabled="record.status === 'pending'"
              >
                {{ $t('account.identity.submit') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <otp-modal
      name="identify_otp_modal"
      :submit="verifyKYC"
      :params="record"
      :is-disable-button="isDisableButton"
      :contact_email="siteSetting.contact_email"
    />
    <PopupModal id="modal-video-record" :isShow="showPopupRecord">
      <div slot="body">
        <div class="video-modal">
          <div class="modal-header">
            <button class="close" type="button" @click="closeRecord">
              <img src="/images/icon/icon-close.svg" alt="icon" />
            </button>
          </div>
          <div class="time-record" v-if="isRecording">{{ timeRecord }}</div>
          <div class="video-wrapper">
            <div v-show="!videoPreview">
              <video id="video-record" class="video-js vjs-default-skin" playsinline></video>
            </div>
            <video v-if="videoPreview" id="video-preview" :src="videoPreview" controls />
            <!-- <video
              v-else
              id="video-preview"
              poster="/images/no-video.png"
              autoplay
            /> -->
          </div>
          <div class="actions">
            <button class="btn-record" @click="retakeRecord" v-if="!isRecording && !videoPreview">
              <img src="/images/btn-record.png" width="50" />
            </button>
            <button class="btn-record" @click="stopRecord" v-if="isRecording">
              <img src="/images/btn-stop-record.png" width="50" />
            </button>
            <template v-if="videoPreview">
              <button class="btn-retake" @click="retakeRecord">
                {{ $t('account.identity.retake') }}
              </button>
              <button class="btn-save" @click="saveRecord">
                {{ $t('account.identity.save') }}
              </button>
            </template>
          </div>
        </div>
      </div>
    </PopupModal>
    <PopupModal id="modal-video-record" :isShow="showTakeCardFront">
      <div slot="body">
        <div class="video-modal">
          <div class="modal-header">
            <button class="close" type="button" @click="toggleTakePhoto(false)">
              <img src="/images/icon/icon-close.svg" alt="icon" />
            </button>
          </div>
          <div class="player-image">
            <video id="take-image" class="video-js vjs-default-skin" playsinline></video>
          </div>
          <div class="actions">
            <button id="startbutton" class="btn-take" @click="takepicture">
              {{ $t('account.identity.take_selfie_photo') }}
            </button>
          </div>
          <canvas id="canvas" ref="canvas" v-show="false"></canvas>
        </div>
      </div>
    </PopupModal>
  </div>
</template>
<script>
import rf from '@/request/RequestFactory';
import Modal from '@/components/shared_components/common/Modal.vue';
import OtpModal from '@/components/shared_components/funds/common/OtpModal';
import AuthenticationUtils from '@/common/AuthenticationUtils';
import SelectBox from '@/components/shared_components/common/SelectBox';
import PopupModal from '@/components/PopupModal.vue';
import DatePicker from 'vuejs-datepicker';
import { en, id } from 'vuejs-datepicker/dist/locale';
import moment from 'moment';
import heic2any from 'heic2any';

const IMAGE_FILE_UPLOAD_SIZE = 4194304; // 4Mb
const KYC_STATUS_REJECTED = 'rejected';
const width = 320;
let height = 0;

import 'video.js/dist/video-js.css';
import 'videojs-record/dist/css/videojs.record.css';
import videojs from 'video.js';
import 'webrtc-adapter';
import RecordRTC from 'recordrtc';
import Record, { async } from 'videojs-record/dist/videojs.record.js';

export default {
  components: {
    Modal,
    OtpModal,
    SelectBox,
    PopupModal,
    DatePicker,
  },
  data() {
    return {
      app_name: process.env.VUE_APP_NAME,
      datePickerLang: this.$i18n.locale === 'id' ? id : en,
      record: {
        id: '',
        user_id: '',
        fullName: '',
        country: 'Indonesia',
        gender: 'male',
        passportID: '',
        srcFront: '',
        srcBack: '',
        srcSelfie: '',
        imageFront: '',
        imageBack: '',
        imageSelfie: '',
        status: '',
        dob: '',
        province: '',
        city: '',
        sub_district: '',
        ward: '',
        postal_code: '',
        address: '',
        error_digisign: '',
        npwp: '',
        job: '',
        income: '',
        source_of_funds: '',
        account_purpose: '',
      },
      previousCountry: '',
      countries: [],
      genderList: [
        { name: this.$i18n.t('account.identity.male'), value: 'male' },
        { name: this.$i18n.t('account.identity.female'), value: 'female' },
      ],
      file: '',
      titleInput: this.$i18n.t('common.no_file_chosen'),
      titleInput1: this.$i18n.t('common.no_file_chosen'),
      titleInput2: this.$i18n.t('common.no_file_chosen'),
      validateErrors: {},
      otp: '',
      status: '',
      errMsg: '',
      isDisableButton: false,
      isChrome: window.navigator.userAgent.toLowerCase().indexOf('chrome') > 0,
      siteSetting: {},
      showHandleButton1: false,
      showHandleButton2: true,
      showHandleButton3: true,
      showHandleButton4: true,
      showTakeCardFront: false,
      streamingCardFront: false,
      intervalId: null,
      blobRecord: null,
      blobRecordImage: '',
      videoPreview: null,
      videoSrc: null,
      timeRecord: '00:00:00',
      bankInfo: {
        bankName: '',
        bankBranch: '',
        accountName: '',
        accountNumber: '',
      },
      showPopupRecord: false,
      isRecording: false,
      theStream: null,
      theRecorder: null,
      recordedChunks: [],
      mediaControl: undefined,
      nameRegex: /^[a-zA-Z ]*$/,
      disabledDates: {
        customPredictor(date) {
          if (date.getTime() > Date.now()) {
            return true;
          }
        },
      },
      player: '',
      options: {
        autoplay: false,
        fluid: false,
        loop: false,
        bigPlayButton: false,
        controlBar: {
          volumePanel: false,
        },
        plugins: {
          record: {
            audio: false,
            video: true,
            debug: true,
            maxLength: 600,
            width: '100%',
            height: '100%',
          },
        },
      },
      playerImage: '',
      optionsImage: {
        controls: true,
        fluid: false,
        bigPlayButton: false,
        controlBar: {
          volumePanel: false,
          fullscreenToggle: false,
        },
        width: 580,
        height: 400,
        plugins: {
          record: {
            debug: true,
            imageOutputType: 'dataURL',
            imageOutputFormat: 'image/jpeg',
            imageOutputQuality: 1,
            image: {
              // image media constraints: set resolution of camera
              width: {
                min: window.innerWidth > 768 ? 580 : 400,
                ideal: window.innerWidth > 768 ? 580 : 400,
                max: 1280,
              },
              height: {
                min: window.innerWidth > 768 ? 400 : 580,
                ideal: window.innerWidth > 768 ? 400 : 580,
                max: 920,
              },
            },
            // dimensions of captured video frames
            frameWidth: 580,
            frameHeight: 400,
          },
        },
      },
      isCorporate: '',
    };
  },
  computed: {
    STORAGE_URL() {
      const masterdata = this.$store.state.masterdata;
      return masterdata.settings.find((item) => item.key === 'storage_url').value;
    },
    isIndoIden() {
      return this.record?.country === 'Indonesia';
    },
  },
  watch: {
    'record.country'() {
      this.resetErrors();
    },
    'record.fullName'(val) {
      this.record.fullName = this.formatName(val);
      if (!this.nameRegex.test(this.record.fullName)) {
        this.record.fullName = this.record.fullName.replace(/[^a-zA-Z ]/g, '');
      }
    },
    'record.postal_code'(val) {
      this.record.postal_code = val.replace(/\D/g, '');
    },
    'record.npwp'(val) {
      this.record.npwp = val.replace(/\D/g, '');
    },
    'record.income'(val) {
      let formated = val.replace(/\D/g, '');
      this.record.income =  formated.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    },
    // showPopupRecord(val) {
    //   if (val) {
    //     document.querySelector('#video_selfie').scrollIntoView();
    //   }
    // },
    // showTakeCardFront(val) {
    //   if (val) {
    //     document.querySelector('#id_selfie').scrollIntoView();
    //   }
    // },
  },
  methods: {
    statusKyc(data) {
      if (data.expired_at && new Date(data.expired_at) < new Date()) {
        this.record.error_digisign.notif = 'kyc.expired';
        return 'rejected';
      } else {
        return data.status;
      }
    },
    obscureEmail(email) {
      const [name, domain] = email.split('@');
      return `${name[0]}${new Array(name.length).join('*')}@${domain}`;
    },
    openPopupContactUs() {
      window.FreshworksWidget('open');
    },
    formatName(name) {
      return name
        .split(' ')
        .map((item) => item.charAt(0).toUpperCase() + item.slice(1))
        .join(' ');
    },
    handleDigisignError(data) {
      if (data.expired) {
        return this.$i18n.t('digisign_error.expired');
      }
      if (data.notif === 'system timeout, silahkan coba kembali 10 menit kemudian') {
        return this.$i18n.t('digisign_error.system_timeout');
      }
      if (
        data.notif ===
        'User tersebut sudah mencoba didaftarkan lebih dari 3x dengan data tidak valid. Silahkan lakukan pengecekan data-data terlebih dahulu.'
      ) {
        return this.$i18n.t('digisign_error.data_invalid');
      }
      if (data.notif === 'format hp harus 62, 08 atau +62') {
        return this.$i18n.t('digisign_error.phone_number_invalid');
      }
      if (data.notif === 'format nama salah') {
        return this.$i18n.t('digisign_error.name_invalid');
      }
      if (data.notif === 'Format NIK Salah') {
        return this.$i18n.t('digisign_error.id_invalid');
      }
      if (data.result === '14') {
        return this.$i18n.t('digisign_error.ktp_taken', {
          email: this.obscureEmail(data.email_registered),
        });
      }
      if (data.data && Object.keys(data.data).length >= 3) {
        const info = data.data;
        if (!info.nik && !info.name && !info.birthdate) {
          return this.$i18n.t('digisign_error.id_not_found');
        }
        if ((info.nik || info.selfie) && !info.name && !info.selfie) {
          return this.$i18n.t('digisign_error.name_selfife_not_match');
        }
        if ((info.nik || info.selfie) && !info.name && !info.birthdate) {
          return this.$i18n.t('digisign_error.name_dob_not_match');
        }
        if ((info.nik || info.selfie) && info.name && !info.birthdate) {
          return this.$i18n.t('digisign_error.dob_not_match');
        }
        if ((info.nik || info.selfie) && !info.name && info.birthdate) {
          return this.$i18n.t('digisign_error.name_not_match');
        } else {
          switch (data.info) {
            case 'No face found on Selfie':
              return this.$i18n.t('digisign_error.no_face_selfie');
            case 'No face found on KTP':
              return this.$i18n.t('digisign_error.no_face_ktp');
            case 'Verifikasi gagal. Pastikan cahaya cukup dan foto ktp sesuai dengan foto wajah.':
              return this.$i18n.t('digisign_error.ktp_invalid');
            case 'Verifikasi wajah gagal':
              return this.$i18n.t('digisign_error.face_verify_failed');
            default:
              return data.notif;
          }
        }
      }

      return data.notif;
    },
    countRecoder() {
      let time = 0;
      let seconds = 0;
      let minutes = 0;
      let hours = 0;
      setInterval(() => {
        seconds++;
        if (seconds === 60) {
          seconds = 0;
          minutes++;
        }
        if (minutes === 60) {
          minutes = 0;
          hours++;
        }
        time = `${hours}:${minutes}:${seconds}`;
      }, 1000);
      return time;
    },
    removeVideo() {
      this.videoSrc = null;
      this.$refs.file3.value = null;
      this.$refs.uploadWrapper4.classList.remove('had-file');
      clearInterval(this.intervalId);
    },
    cameraError(err) {
      switch (err.name) {
        case 'NotFoundError':
          Message.error(this.$i18n.t('common.camera_not_found'));
          break;
        case 'NotAllowedError':
          Message.error(this.$i18n.t('common.camera_not_permisson'));
          break;
        case 'NotReadableError':
          Message.error(this.$i18n.t('common.camera_not_readable'));
          break;
        default:
          Message.error(err.message);
          break;
      }
    },
    startup() {
      const video = this.$refs.video;
      const canvas = this.$refs.canvas;
      navigator.mediaDevices
        .getUserMedia({ video: true, audio: false })
        .then((stream) => {
          this.showTakeCardFront = true;
          video.srcObject = stream;
          video.play();
        })
        .catch((err) => {
          this.cameraError(err);
        });

      video.addEventListener(
        'canplay',
        (ev) => {
          if (!this.streamingCardFront) {
            height = video.videoHeight / (video.videoWidth / width);

            if (isNaN(height)) {
              height = width / (4 / 3);
            }

            video.setAttribute('width', width);
            video.setAttribute('height', height);
            canvas.setAttribute('width', width);
            canvas.setAttribute('height', height);
            this.streamingCardFront = true;
          }
        },
        false
      );
    },
    takepicture() {
      // const canvas = this.$refs.canvas;
      // const context = canvas.getContext('2d');
      // canvas.width = 200;
      // canvas.height = 200;
      // context.drawImage(video, 0, 0, 200, 200);
      // const data = canvas.toDataURL("image/jpeg", 1);

      // this.$refs.video.srcObject?.getTracks()?.forEach(function (track) {
      //   track?.stop();
      // });
      // this.showHandleButton3 = true;
      this.playerImage?.record().createSnapshot();
      this.resetErrors();
    },
    async toggleTakePhoto(value) {
      if (value) {
        this.playerImage?.record().getDevice();
        const permissionStatus = await navigator.permissions.query({
          name: 'camera',
        });
        if (permissionStatus.state !== 'granted') return;

        this.showTakeCardFront = true;
        this.playerImage.on('finishRecord', async () => {
          // this.record.srcSelfie = '';
          // this.$refs.imageSelfie.src = this.playerImage.recordedData;
          // this.showHandleButton3 = true;
          function urltoFile(url, filename, mimeType) {
            return fetch(url)
              .then((res) => res.arrayBuffer())
              .then((buf) => new File([buf], filename, { type: mimeType }));
          }
          // setTimeout(async() => {
          const file = await urltoFile(this.playerImage.recordedData, 'selfie.jpg', 'image/jpeg');
          const dataTransfer = new DataTransfer();
          dataTransfer.items.add(file);
          this.$refs.file2 = dataTransfer;
          this.showHandleButton3 = true;
          this.record.srcSelfie = '';
          this.$refs.imageSelfie2.src = this.playerImage.recordedData;

          this.$refs.uploadWrapper3.classList.add('had-file');
          this.showTakeCardFront = false;
          this.playerImage.record().stopDevice();
          // }, 500)
        });
      } else {
        this.showTakeCardFront = false;
        this.playerImage.record().stopDevice();
      }

      // if (value === false) {
      //   this.showTakeCardFront = value;
      //   this.$refs.video.srcObject?.getTracks()?.forEach(function (track) {
      //     track?.stop();
      //   });
      // }
    },
    removeImg(input, ref, refUploadWrapper, showHandleButton, type) {
      if (input.value) {
        input.value = null;
      }
      switch (type) {
        case 'front':
          this.record.srcFront = '';
          break;
        case 'back':
          this.record.srcBack = '';
          break;
        case 'selfie':
          this.record.srcSelfie = '';
          this.$refs.file2 = null;
          break;
        default:
          break;
      }
      ref.removeAttribute('src');
      ref.removeAttribute('alt');
      refUploadWrapper.classList.remove('had-file');
      this[showHandleButton] = false;
      this.resetErrors();
    },
    resetErrors() {
      if (this.errors.any()) {
        this.errors.clear();
      }
    },
    checkShowByLocale() {
      return AuthenticationUtils.getLocale();
    },
    convertUrl(url, name, ref) {
      if (
        name.includes('heic') ||
        name.includes('heif') ||
        name.includes('HEIC') ||
        name.includes('HEIF')
      ) {
        fetch(url)
          .then((res) => res.blob())
          .then((blob) =>
            heic2any({
              blob,
              toType: 'image/jpg',
              quality: 1,
            })
          )
          .then((conversionResult) => {
            ref.src = URL.createObjectURL(conversionResult);
          });
      }
    },
    previewImage(event, ref, flagTitle, showHandleButton, uploadWrapper) {
      this.resetErrors();
      const input = event.target;
      if (input.files && input.files[0]) {
        const reader = new FileReader();
        reader.onload = (e) => {
          ref.src = e.target.result;
          if (flagTitle === 1 || flagTitle === 2) {
            this.convertUrl(e.target.result, input.files[0].name, ref);
          }
          this.$refs[uploadWrapper].classList.add('had-file');
          this[showHandleButton] = true;
        };
        reader.readAsDataURL(input.files[0]);

        switch (flagTitle) {
          case 1:
            this.titleInput = input.values;
            break;
          case 2:
            this.titleInput1 = input.values;
            break;
          case 3:
            this.titleInput2 = input.values;
            break;
          default:
            break;
        }
      }
    },
    previewVideo(event) {
      const blob = new Blob([event.target.files[0]], {
        type: event.target.files[0].type,
      });
      const url = URL.createObjectURL(blob);
      this.videoSrc = url;
      this.$refs.uploadWrapper4.classList.add('had-file');
    },
    isValidForm() {
      const imageFront = this.$refs.file.files[0] || this.record.srcFront;
      const imageBack = this.$refs.file1.files[0] || this.record.srcBack;
      const imageSelfie = this.$refs.file2?.files[0] || this.record.srcSelfie;
      const videoSelfie = this.videoSrc;
      this.errors.clear();

      this.validateRequired(this.record.fullName.trim(), 'fullName');
      this.validateRequired(this.record.passportID, 'id_number');
      this.validateFormatNumber(this.record.passportID, 'id_number');
      this.validateRequired(this.record.gender, 'gender');

      this.validateImage(imageFront, 'id_front');
      this.validateImage(imageBack, 'id_back');
      this.validateImage(imageSelfie, 'id_selfie');
      this.validateRequired(videoSelfie, 'video_selfie');
      this.validateRequired(this.record.address, 'address');
      this.validateRequired(this.record.postal_code, 'postal_code');
      this.validateRequired(this.record.npwp, 'npwp');
      if (this.record.npwp.length != 15) {
        this.errors.add('npwp', 'This field should contain 15-digits');
      }
      this.validateRequired(this.record.job, 'job');
      this.validateRequired(this.record.income, 'income');
      this.validateRequired(this.record.source_of_funds, 'source_of_funds');
      this.validateRequired(this.record.account_purpose, 'account_purpose');
      this.validateRequired(
        this.record.dob && moment(this.record.dob).format('DD-MM-YYY'),
        'date_of_birth'
      );

      if (this.isIndoIden) {
        // this.validateRequired(this.record.city, 'city');
        // this.validateRequired(this.record.province, 'province');
        // this.validateRequired(this.record.sub_district, 'sub_district');
        // this.validateRequired(this.record.ward, 'ward');
        const yearOld = moment().diff(moment(this.record.dob), 'years');
        if (yearOld < 17) {
          this.errors.add('date_of_birth', this.$i18n.t('account.identity.errors.under_17'));
        }
      }

      return !this.errors.count();
    },
    validateRequired(value, field) {
      if (_.isEmpty(value)) {
        this.errors.add(field, this.$i18n.t('account.identity.errors.required'));
      }
    },
    validateKYCRequired(value, field) {
      if (_.isEmpty(value)) {
        this.errors.add(field, this.$i18n.t('validation.kyc.' + field + '_required'));
      }
    },
    validateImage(file, field) {
      const exts = ['.jpg', '.jpeg', '.png', '.heic', '.heif'];
      const fileType = file?.type;
      const fileName = file?.name?.toLowerCase();
      const fileSize = file?.size;
      if (!file) {
        this.errors.add(field, this.$i18n.t('account.identity.errors.' + field + '.required'));
      } else if (typeof file !== 'string' && !fileType) {
        if (!exts.find((item) => fileName.includes(item))) {
          this.errors.add(
            field,
            this.$i18n
              .t('validation.custom.image')
              .formatUnicorn({ values: '.jpg, .jpeg, .png, .heic, .heif' })
          );
        }
      } else if (
        typeof file !== 'string' &&
        typeof fileType === 'string' &&
        fileType.split('/')[0] !== 'image'
      ) {
        this.errors.add(
          field,
          this.$i18n
            .t('validation.custom.image')
            .formatUnicorn({ values: '.jpg, .jpeg, .png, .heic, .heif' })
        );
      } else if (typeof file !== 'string' && fileSize > IMAGE_FILE_UPLOAD_SIZE) {
        this.errors.add(field, this.$i18n.t('account.identity.errors.maxsize'));
      } else if (
        typeof file !== 'string' &&
        typeof fileName === 'string' &&
        !new RegExp('(' + exts.join('|').replace(/\./g, '\\.') + ')$').test(fileName.toLowerCase())
      ) {
        this.errors.add(field, this.$i18n.t('validation.kyc.id_front_mimes'));
      }
    },
    validateFormatNumber(value, field) {
      var regsaid = /^[a-zA-Z0-9-]+$/;
      if (!regsaid.test(value)) {
        this.errors.add(field, this.$i18n.t('account.identity.errors.format_number'));
      }
      /*
        const number = parseFloat(value);
        if (number <= 0 || !window._.isInteger(number)) {
          this.errors.add(field, this.$i18n.t('account.identity.errors.format_number'));
        }
        */
    },
    validateCharacterLimit(value, field, min, max) {
      const inValid = !window._.inRange(value.length, min, max + 1);
      if (inValid) {
        this.errors.add(
          field,
          this.$i18n.t('account.identity.errors.format_number')
          // this.$i18n.t('account.identity.errors.character_limit', {
          //   min: min,
          //   max: max
          // })
        );
      }
    },
    saveIdentity() {
      if (!this.isValidForm()) {
        return;
      }
      if (this.record.status === 'verified') {
        return;
      }
      CommonModal.show('identify_otp_modal');
    },
    async verifyKYC(params) {
      this.isDisableButton = true;
      params.imageFront = this.$refs.file.files[0];
      params.imageBack = this.$refs.file1.files[0];
      params.imageSelfie = this.$refs.file2.files[0];
      params.videoSelfie = this.$refs.file3.files[0];
      await this.createIdentity(params);
    },
    createIdentity(params) {
      return rf
        .getRequest('UserRequest')
        .createIdentity(this.getParams(params))
        .then((res) => {
          CommonModal.hide('identify_otp_modal');
          if (this.isIndoIden && this.isCorporate !== 'true') {
            this.convertResponse(res.data);
            if (res.data.redirect_digisign) {
              window.open(res.data.redirect_digisign, '_blank');
              this.showSuccess(this.$i18n.t('account.identity.update_digisign_success'));
              rf.getRequest('UserRequest')
                .getUserKyc()
                .then((res) => {
                  this.convertResponse(res.data);
                  this.previousCountry = this.record.country;
                });
            } else {
              this.showSuccess(this.$i18n.t('account.identity.update_success'));
              this.$router.push({ path: '/account' });
            }
          } else {
            this.showSuccess(this.$i18n.t('account.identity.update_success'));
            this.$router.push({ path: '/account' });
          }
          this.isDisableButton = false;
        })
        .catch((error) => {
          this.isDisableButton = false;
          params.imageFront = '';
          params.imageBack = '';
          params.imageSelfie = '';
          if (!error.response) {
            this.showError(this.$i18n.t('common.message.network_error'));
            return;
          }
          const errors = error.response.data.errors;

          if (errors) {
            this.validateErrors = errors;
            window._.each(this.validateErrors, (value, key) => {
              if (key === 'otp') {
                this.errors.add({
                  field: key,
                  msg: this.$i18n.t(errors[key][0]),
                });
              } else {
                this.errors.add(
                  key,
                  this.$i18n.t(value[0].formatUnicorn({ values: '.jpg, .jpeg, .png' }))
                );
              }
            });
            if (!this.errors.first('otp')) {
              CommonModal.hide('identify_otp_modal');
              window.scrollTo(0, 0);
            }
          } else {
            CommonModal.hide('identify_otp_modal');
            window.scrollTo(0, 0);
            if (error.response.data.message.error_digisign) {
              this.convertResponse(error.response.data.message);
              if (this.handleDigisignError(this.record.error_digisign) !== 'Unknown error!')
                this.showError(this.handleDigisignError(this.record.error_digisign));
            } else {
              this.showError(this.$i18n.t(error.response.data.message));
            }
            rf.getRequest('UserRequest')
              .getUserKyc()
              .then((res) => {
                this.convertResponse(res.data);
                this.previousCountry = this.record.country;
              });
          }
        });
    },

    getParams(data) {
      let fd = new FormData();
      fd = this.addFormData(fd, 'id', data.id);
      fd = this.addFormData(fd, 'user_id', data.user_id);
      fd = this.addFormData(fd, 'full_name', data.fullName);
      fd = this.addFormData(fd, 'country', data.country);
      fd = this.addFormData(fd, 'gender', data.gender);
      fd = this.addFormData(fd, 'id_number', data.passportID);
      fd = this.addFormData(fd, 'id_front', data.imageFront);
      fd = this.addFormData(fd, 'id_back', data.imageBack);
      fd = this.addFormData(fd, 'id_selfie', data.imageSelfie);
      fd = this.addFormData(fd, 'otp', data.otp);
      fd = this.addFormData(fd, 'video_selfie', data.videoSelfie);
      fd = this.addFormData(fd, 'address', data?.address);
      fd = this.addFormData(fd, 'postal_code', data?.postal_code);
      fd = this.addFormData(fd, 'npwp', data?.npwp);
      fd = this.addFormData(fd, 'job', data?.job);
      fd = this.addFormData(fd, 'income', data?.income);
      fd = this.addFormData(fd, 'source_of_funds', data?.source_of_funds);
      fd = this.addFormData(fd, 'account_purpose', data?.account_purpose);
      fd = this.addFormData(fd, 'date_of_birth', moment(data?.dob).format('DD-MM-YYYY'));

      if (this.isIndoIden && this.isCorporate !== 'true') {
        this.addFormData(fd, 'city', data?.city);
        this.addFormData(fd, 'province', data?.province);
        this.addFormData(fd, 'sub_district', data?.sub_district);
        this.addFormData(fd, 'ward', data?.ward);
        this.addFormData(fd, 'type', 'digisign');
      }
      return fd;
    },

    addFormData(form, key, value) {
      if (value) {
        form.append(key, value);
      }
      return form;
    },

    convertResponse(data) {
      if (!data) {
        return;
      }
      this.record.error_digisign = JSON.parse(data.error_digisign) || {};

      this.record.id = data.id;
      this.record.user_id = data.user_id;
      this.record.fullName = data.full_name;
      this.record.country = data.country;
      this.record.gender = data.gender;
      this.record.passportID = data.id_number;
      this.record.srcFront = data.id_front;
      this.record.srcBack = data.id_back;
      this.record.srcSelfie = data.id_selfie;
      this.record.imageFront = '';
      this.record.imageBack = '';
      this.record.imageSelfie = '';
      this.record.status = this.statusKyc(data);
      this.record.dob = data.date_of_birth
        ? new Date(moment(data.date_of_birth, 'YYYY-MM-DD'))
        : '';
      this.record.province = data.province;
      this.record.city = data.city;
      this.record.sub_district = data.sub_district;
      this.record.ward = data.ward;
      this.record.postal_code = data.postal_code;
      this.record.npwp = data.npwp;
      this.record.job = data.job;
      this.record.income = data.income;
      this.record.source_of_funds = data.source_of_funds;
      this.record.account_purpose = data.account_purpose;
      this.record.address = data.address;
      this.videoSrc = data.video_selfie ? this.STORAGE_URL + '/' + data.video_selfie : null;

      if (this.record.srcFront) {
        this.$refs.uploadWrapper.classList.add('had-file');
      }
      if (this.record.srcBack) {
        this.$refs.uploadWrapper2.classList.add('had-file');
      }
      if (this.record.srcSelfie) {
        this.$refs.uploadWrapper3.classList.add('had-file');
      }
      if (this.videoSrc) {
        this.$refs.uploadWrapper4.classList.add('had-file');
      }
    },

    showSuccess(message) {
      Message.success(message, {});
    },
    showError(message) {
      Message.error(message);
    },

    showKycVerifiedMessage() {
      window.ConfirmationModal.show({
        type: 'warning',
        title: '',
        content: this.$i18n.t('kyc.msg_verified'),
        btnCancelLabel: '',
        btnConfirmLabel: '',
        onConfirm: () => {},
        onCancel: () => {},
      });
    },

    showOtpUnverifiedMessage() {
      window.ConfirmationModal.show({
        type: 'warning',
        title: '',
        content: this.$i18n.t('account.otp.should_enable_first'),
        btnCancelLabel: '',
        btnConfirmLabel: '',
        onConfirm: () => {},
        onCancel: () => {},
      });
    },

    redirectAccountPageIfNeed(callback) {
      setTimeout(() => {
        callback();
        this.$router.push({ name: 'Account' });
      }, 300);
    },
    getMasterData() {
      rf.getRequest('MasterdataRequest')
        .getAll()
        .then((data) => {
          const masterdata = data;
          window._.each(masterdata.settings, (item) => {
            this.siteSetting[item.key] = item.value;
          });
        });
    },
    stopCamera() {
      this.videoPreview = null;
      this.theRecorder?.stop();
      this.theStream?.getTracks()?.forEach((track) => {
        track?.stop();
      });
      clearInterval(this.intervalId);
    },
    closeRecord() {
      // this.stopCamera();
      // if (this.isRecording) {
      //   this.isRecording = false;
      //   if (navigator.mozGetUserMedia) {
      //     this.mediaControl.mozSrcObject = null;
      //   } else {
      //     this.mediaControl.srcObject = null;
      //   }
      // }
      this.player.record().stopDevice();
      this.showPopupRecord = false;
      clearInterval(this.intervalId);
    },
    handleRetake() {
      this.showPopupRecord = true;
      this.isRecording = false;
      this.videoPreview = false;

      this.player.record().getDevice();
      this.player.on('finishRecord', () => {
        const url = URL.createObjectURL(this.player.recordedData);
        this.videoPreview = url;
        clearInterval(this.intervalId);
        this.isRecording = false;
      });

      // this.previewVideo = null;
      // this.isRecording = false;
      // navigator.mediaDevices
      //   .getUserMedia({ video: true, audio: false })
      //   .then((stream) => {
      //     this.showPopupRecord = true;
      //     this.mediaControl = document.getElementById('video-preview');
      //     if (navigator.mozGetUserMedia) {
      //       this.mediaControl.mozSrcObject = stream;
      //     } else {
      //       this.mediaControl.srcObject = stream;
      //     }

      //     this.theStream = stream || {};
      //     try {
      //       var recorder = new MediaRecorder(stream);
      //     } catch (e) {
      //       console.error('Exception while creating MediaRecorder: ' + e);
      //       return;
      //     }
      //     this.theRecorder = recorder;
      //     recorder.ondataavailable = this.recorderOnDataAvailable;
      //     recorder.start(0);
      //   })
      //   .catch((err) => {
      //     this.cameraError(err);
      //   });
    },
    getUserMedia(options, successCallback, failureCallback) {
      var api =
        navigator.getUserMedia ||
        navigator.webkitGetUserMedia ||
        navigator.mozGetUserMedia ||
        navigator.msGetUserMedia ||
        navigator.mediaDevices.getUserMedia;
      if (api) {
        return api.bind(navigator)(options, successCallback, failureCallback);
      }
      Message.error('User Media API not supported.');
    },

    formatTime(time) {
      return time < 10 ? `0${time}` : time;
    },

    getStream() {
      this.stopCamera();
      this.recordedChunks = [];
      this.isRecording = true;
      navigator.mediaDevices
        .getUserMedia({ video: true, audio: false })
        .then((stream) => {
          this.mediaControl = document.getElementById('video-preview');
          if (navigator.mozGetUserMedia) {
            this.mediaControl.mozSrcObject = stream;
          } else {
            this.mediaControl.srcObject = stream;
          }

          this.theStream = stream || {};
          try {
            var recorder = new MediaRecorder(stream);
          } catch (e) {
            console.error('Exception while creating MediaRecorder: ' + e);
            return;
          }
          this.theRecorder = recorder;
          recorder.ondataavailable = this.recorderOnDataAvailable;
          recorder.start(0);
        })
        .catch((err) => {
          this.cameraError(err);
        });
      let seconds = 0;
      let minutes = 0;
      let hours = 0;
      this.intervalId = setInterval(() => {
        seconds++;
        if (seconds === 60) {
          seconds = 0;
          minutes++;
        }
        if (minutes === 60) {
          minutes = 0;
          hours++;
        }
        this.timeRecord = `${this.formatTime(hours)}:${this.formatTime(minutes)}:${this.formatTime(
          seconds
        )}`;
      }, 1000);
    },

    recorderOnDataAvailable(event) {
      if (event.data.size == 0) return;
      this.recordedChunks.push(event.data);
    },
    async retakeRecord() {
      this.timeRecord = '00:00:00';
      this.player.record().start();
      this.isRecording = true;
      this.videoPreview = false;
      let seconds = 0;
      let minutes = 0;
      let hours = 0;
      this.intervalId = setInterval(() => {
        seconds++;
        if (seconds === 60) {
          seconds = 0;
          minutes++;
        }
        if (minutes === 60) {
          minutes = 0;
          hours++;
        }
        this.timeRecord = `${this.formatTime(hours)}:${this.formatTime(minutes)}:${this.formatTime(
          seconds
        )}`;
      }, 1000);
      // this.theRecorder?.stop();
      // this.theStream?.getTracks()?.forEach((track) => {
      //   track?.stop();
      // });
      // setTimeout(() => {
      // this.getStream();
      // }, 100);
    },

    blobToBase64(blob) {
      return new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
      });
    },

    urltoFile(url, filename, mimeType) {
      return fetch(url)
        .then((res) => res.arrayBuffer())
        .then((buf) => new File([buf], filename, { type: mimeType }));
    },
    stopRecord() {
      this.player.record().stop();
      // this.isRecording = false;
      // clearInterval(this.intervalId);
      // setTimeout(() => {
      //   const url = URL.createObjectURL(this.blobRecord);
      //   this.videoPreview = url;
      // }, 500)
      //   this.stopCamera();
      //   this.blobRecord = new Blob(this.recordedChunks, { type: 'video/mkv' });
      //   const urlRecord = (window.URL || window.webkitURL).createObjectURL(
      //     this.blobRecord
      //   );
      //   this.videoPreview = urlRecord;
      //   this.isRecording = false;
      //   if (navigator.mozGetUserMedia) {
      //     this.mediaControl.mozSrcObject = null;
      //   } else {
      //     this.mediaControl.srcObject = null;
      //   }
      // },
      // urltoFile(url, filename, mimeType) {
      //   return fetch(url)
      //     .then((res) => res.arrayBuffer())
      //     .then((buf) => new File([buf], filename, { type: mimeType }));
      // },
      // async saveRecord() {
      //   this.videoSrc = this.videoPreview;
      //   const file = await this.urltoFile(
      //     this.videoPreview,
      //     'selfie.mkv',
      //     'video/mkv'
      //   );
      //   this.videoPreview = null;
      //   const dataTransfer = new DataTransfer();
      //   dataTransfer.items.add(file);
      //   this.$refs.file3 = dataTransfer;
      //   this.showPopupRecord = false;
    },
    async saveRecord() {
      this.resetErrors();
      this.player.record().stopDevice();
      this.videoSrc = this.videoPreview;
      const file = await this.urltoFile(this.videoPreview, 'selfie.mkv', 'video/mkv');
      const dataTransfer = new DataTransfer();
      dataTransfer.items.add(file);
      this.$refs.file3 = dataTransfer;
      this.showPopupRecord = false;
      this.$refs.uploadWrapper4.classList.add('had-file');
    },
  },
  beforeMount() {
    rf.getRequest('UserRequest')
      .getCurrentUser()
      .then((res) => {
        this.isCorporate = res.data.is_corporate;
        const securitySetting = res.data.security_setting;
        if (!securitySetting.otp_verified) {
          this.redirectAccountPageIfNeed(this.showOtpUnverifiedMessage);
        }
        if (securitySetting.identity_verified) {
          this.redirectAccountPageIfNeed(this.showKycVerifiedMessage);
        }
      });
  },
  beforeDestroy() {
    if (this.player) this.player.record().destroy();
    if (this.playerImage) this.playerImage.record().destroy();
  },
  mounted() {
    this.player = videojs('#video-record', this.options);
    this.playerImage = videojs('#take-image', this.optionsImage);
    this.playerImage.on('deviceError', () => {
      if (this.playerImage.deviceErrorCode.toString().includes('Could not start video source')) {
        Message.error(this.$i18n.t('common.camera_not_readable'));
      }
      if (this.playerImage.deviceErrorCode.toString().includes('Permission denied')) {
        Message.error(this.$i18n.t('common.camera_not_permisson'));
      }
    });
    this.player.on('deviceError', () => {
      if (this.player.deviceErrorCode.toString().includes('Could not start video source')) {
        Message.error(this.$i18n.t('common.camera_not_readable'));
      }
      if (this.player.deviceErrorCode.toString().includes('Permission denied')) {
        Message.error(this.$i18n.t('common.camera_not_permisson'));
      }
    });
    this.getMasterData();
    document.title = this.$i18n.t('account.identity.header_identity') + ` – ${this.tabTitle}`;
    rf.getRequest('MasterdataRequest')
      .getAll()
      .then((res) => {
        this.countries = res.countries;
        this.countries.sort((a, b) => (a.name > b.name ? 1 : -1));
        this.countries = this.countries.filter((item) => item.name !== 'China');
      });
    rf.getRequest('UserRequest')
      .getUserKyc()
      .then((res) => {
        this.convertResponse(res.data);
        this.previousCountry = this.record.country;
      });
    window.addEventListener('keyup', (event) => {
      if (event.key === 'Enter' && this.$route.name == 'IdentityPersonal') {
        this.saveIdentity();
      }
    });
    this.$on('UPDATED_LOCALE', () => {
      this.titleInput = this.$i18n.t('common.no_file_chosen');
      this.titleInput1 = this.$i18n.t('common.no_file_chosen');
      this.titleInput2 = this.$i18n.t('common.no_file_chosen');
    });
  },
};

String.prototype.formatUnicorn =
  String.prototype.formatUnicorn ||
  function () {
    'use strict';
    var str = this.toString();
    if (arguments.length) {
      var t = typeof arguments[0];
      var key;
      var args =
        'string' === t || 'number' === t ? Array.prototype.slice.call(arguments) : arguments[0];

      for (key in args) {
        str = str.replace(new RegExp('\\{' + key + '\\}', 'gi'), args[key]);
      }
    }
    return str;
  };
</script>

<style lang="scss">
.identity-form__dob {
  .identity__date-input {
    border-radius: 12px;
    border: 1px solid #c7cbd3;
    width: 100%;
    padding: 9.5px 15px;
    width: 100%;
    font-family: 'Mulish-Medium', sans-serif;
    color: #001336;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
  }

  &.input-error {
    .identity__date-input {
      border-color: #ff6666 !important;
    }
  }
}
</style>

<style lang="scss" scoped>
@import '@/assets/sass/common.scss';

:deep #identify_otp_modal .modal-dialog {
  width: 100%;
}

#video {
  width: 100%;
  height: auto;
}

.w_100_max_1000 {
  width: 100%;
  max-width: 1000px;
}

// 12/6/2023 --- truongvx
.identity__calendar-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 12px;
  cursor: pointer;
}

.group-tooltip {
  color: $color-red;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  display: flex;
  align-items: center;
  gap: 8px;

  .icon-help {
    position: relative;
    pointer-events: initial;

    &::before {
      font-size: 20px;
    }
  }

  .box-tooltip {
    &::before {
      content: '';
      position: absolute;
      left: 50%;
      bottom: 100%;
      translate: -50%;
      border: 5px solid transparent;
      border-bottom-color: $tool-tip-color;
    }

    padding: 10px;
    width: 250px;
    position: absolute;
    display: none;
    z-index: 999999;
    text-align: center;
    background: $tool-tip-color;
    left: 50%;
    top: calc(100% + 0.75rem);
    translate: -50%;

    .bubble {
      font-family: $helvetica;
      color: #ffffff;
      font-size: 14px;
      font-weight: 400;
      line-height: 25px;
      text-align: center;
      width: 100%;
    }
  }

  &:hover .box-tooltip {
    display: block;
  }
}

.identity-form__country-select .identity-form-group {
  margin-bottom: 16px;
  max-width: 275px;

  @include mobile {
    max-width: 100%;
  }
}

.input-group {
  margin-bottom: 0;
}

.digisign-status {
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  gap: 8px 16px;

  .wrap-status {
    @include mobile {
      width: 100%;
    }
  }

  .status {
    @include btn-common(contained, sm, false);
    color: $text-main1;
    cursor: initial;
    width: auto;
    display: inline-block;

    &.rejected {
      background-color: $color-red;
    }

    &.pending {
      background-color: $color-blue;
    }
  }

  .country-select-logo {
    img {
      width: 78.75px;
    }
  }
}

.reason-error {
  &.mobile {
    display: none;
  }

  @include mobile {
    &.mobile {
      display: block;
      float: right;
      margin-bottom: 0;
    }

    &.desktop {
      display: none;
    }
  }

  margin-bottom: 16px;

  .invalid-reason {
    color: $color-red;
    font-size: 14px;
    font-weight: 300;
    line-height: 22px;
  }
}

.country-select__notice {
  margin-top: 20px;
  margin-bottom: 12px;
  font-size: 14px;
  line-height: 18px;
  color: #666666;
  display: flex;

  & span {
    font-weight: 700;
    color: #000000;
  }

  .country-select-logo {
    height: 30px;
    margin-left: 10px;

    img {
      height: 100%;
      width: auto;
      margin-top: -5px;
    }
  }
}

.identity-form__multiple-col-item {
  grid-column: 1/4;
}

.identity-col-4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 24px;
  justify-content: space-between;

  @include tablet {
    grid-template-columns: repeat(2, 1fr);
  }

  & + .identity-col-4 {
    margin-top: 16px;
  }
}

.identity-col-2 {
  margin-top: 16px;
  margin-bottom: 16px;
  gap: 24px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

// -----------------------
@media screen and (max-width: 5760px) and (min-width: 2560px) {
  #identityForm .image-error {
    border: 2px solid #e2221e !important;
  }
}

@media screen and (min-width: 5760px) {
  #identityForm .image-error {
    border: 4px solid #e2221e !important;
  }
}

.input-error {
  border-color: $secondary-red !important;
}

.required {
  color: $color-red;
}

#identity {
  max-width: 1370px;
  margin: 0 auto;
  padding: 24px 32px;

  :deep(.box_list_search_select) {
    width: 100%;

    ul {
      li {
        padding: 9.5px 15px;
      }
    }
  }

  .btn-submit {
    margin-top: 35px;
    @include btn-common(contained, lg);
    width: auto;
  }

  .section {
    width: 100%;

    .header-identity {
      color: rgba($color: $text-main2, $alpha: 0.87);
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 40px;

      @include mobile {
        font-size: 18px;
        line-height: 32px;
      }
    }

    .description {
      color: rgba($color: $text-main2, $alpha: 0.6);
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: 22px;

      :deep {
        a {
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;
          color: rgba($color: $text-main2, $alpha: 0.87);
        }
      }
    }

    .form-identity-container {
      color: $color-grey-dusty;
      line-height: 20px;
      font-size: 14px;

      .label-verification {
        &__description {
          span {
            @include text-font($helvetica, $dark-5, 16px, 500, 27px);
          }

          .list-description {
            :deep(div) {
              @include text-font($helvetica, $dark-5, 16px, 500, 27px);
            }
          }
        }

        @include mobile {
          &__description {
            span {
              @include text-font($helvetica, $dark-5, 14px, 500, 25px);
            }

            .list-description {
              :deep(div) {
                @include text-font($helvetica, $dark-5, 14px, 500, 25px);
              }
            }
          }
        }
      }
    }
  }
}

#identityForm {
  background: $color-white;
  padding: 27px 26px;
  border-radius: 10px;
  margin-top: 24px;

  &.disabled {
    pointer-events: none;

    .form-input {
      background: #f5f5f5 !important;

      input {
        background: #f5f5f5;
      }
    }

    :deep(input) {
      background: #f5f5f5 !important;
    }

    :deep(.button_drop_search) {
      background: #f5f5f5 !important;
    }

    video {
      pointer-events: auto;
    }
  }

  .group-info {
    .right_iden {
      display: none;
      flex: 2;

      .example-description {
        @include text-font($helvetica, $dark-5, 14px, 500, 25px);
      }
    }

    .mid_iden {
      display: flex;
      gap: 20px;
      align-items: flex-end;

      .box {
        width: 40%;
        max-width: 472px;
      }

      .description {
        width: 60%;

        p {
          margin-bottom: 0;
          margin-top: 16px;
        }
      }

      .box_add_img_iden {
        // width: 472px;
        max-width: 100%;
        height: 324px;
        padding: 50px 70px;
        background-color: $bg-main5;
        position: relative;

        .img-wrapper {
          position: relative;

          .video-wrapper {
            position: relative;
            height: 224px;
          }
        }

        .fake-img,
        img:not(.cloud-upload, .close-icon) {
          width: 100%;
          height: 224px;
          background-color: $color-white;
          border: 1px dashed $dark-4;
          object-fit: contain;
        }

        .close-icon {
          width: 21px;
          height: 21px;
          min-height: unset;
          background: transparent;
          position: absolute;
          right: 12px;
          top: 12px;
          // display: none;
          cursor: pointer;
          transition: all 0.3s ease-in-out;
          display: none;

          @include mobile {
            right: -10px;
            top: -10px;
          }
        }

        .upload-wrapper {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          opacity: 1;
        }

        .show-upload {
          opacity: 1;

          .button_add_file_iden {
            input {
              border-color: $color-white;
              color: $color-white;
            }
          }
        }

        .cloud-upload {
          width: 60px;
          height: 60px;
          min-height: unset;
          background: transparent;
          top: 35%;
          left: 50%;
          transform: translate(-50%, -50%);
          position: absolute;
        }
      }
    }

    .mid_iden.had-file {
      .box_add_img_iden .close-icon {
        display: block;

        &:hover {
          border-radius: 50%;
          background-color: $color-white;
          filter: brightness(2.5);
        }
      }

      .upload-wrapper,
      .video-wrapper {
        .cloud-upload {
          display: none;
        }

        .button_add_file_iden {
          border: 1px solid $text-main1;
          color: $text-main1;

          &:hover {
            color: $text-main2;
          }
        }
      }

      .box_add_img_iden img {
        background-color: transparent;
        border: none;
      }
    }

    @include mobile {
      flex-direction: column;

      .mid_iden {
        .box_add_img_iden {
          padding: 16px 22px;
          height: 100%;
        }
      }
    }
  }

  .bank-info {
    max-width: 720px;
    background: $grey-3;
    border-radius: 8px;
    margin-top: 20px;
    margin-bottom: 30px;
    padding: 20px;

    .row-group {
      .row-name {
        font-family: $helvetica;
        font-size: 16px;
        line-height: 20px;
        color: $dark-1;
      }

      .grid-2 {
        margin-top: 10px;
        margin-bottom: 20px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;

        input {
          font-family: $helvetica;
          font-size: 16px;
          color: $dark-1;
          padding: 10px 15px;
          background: white;
          border: 1px solid #cfcfcf;
          border-radius: 12px;
        }
      }
    }
  }

  .kyc-notice {
    margin-top: 32px;
    margin-bottom: 8px;

    &.mt {
      margin-top: 0;
    }

    :deep {
      strong {
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
      }

      ul {
        margin-top: 8px;
        margin-bottom: 0;
        list-style-position: inside;

        li {
          color: $text-secondary;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;

          &::before {
            content: '•';
            color: #666666;
            font-weight: bold;
            display: inline-block;
            width: 20px;
            text-align: center;
          }
        }
      }
    }
  }

  .group_iden {
    display: block;
    width: 100%;

    .left_content_iden_title {
      margin-top: 32px;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 40px;
      margin-bottom: 20px;
      color: rgba($color: $text-main2, $alpha: 0.87);
    }

    .group_input {
      display: flex;
      gap: 24px;

      .identity-form-group {
        flex: 1;
      }

      @include mobile {
        flex-direction: column;
        gap: 0;

        .identity-form-group + .identity-form-group {
          margin-top: 16px;
        }
      }
    }
  }

  .identity-form-group {
    .identity-form-label {
      margin-bottom: 8px;
      color: rgba($color: $text-main2, $alpha: 0.6);
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px;
    }

    .identity-form-input {
      &--icon-suffix {
        position: relative;
      }

      :deep() .button_drop_search {
        border: 1px solid $bg-main2;
      }

      .date-picker-input {
        border: 1px solid transparent;

        &.input-error {
          :deep() {
            input {
              border: 1px solid transparent;
            }
          }
        }
      }

      :deep().vdp-datepicker {
        border-radius: 10px;

        input {
          margin-right: 0;
          padding: 10px 16px;
          width: 100%;
          color: rgba($color: $text-main2, $alpha: 0.87);
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 32px;
          background-image: url(@/assets/images/wallet/calendar_icon.png);
          background-size: 24px 24px;
          background-repeat: no-repeat;
          background-position: top 14px right 14px;
          border: 1px solid $bg-main2;
          border-radius: 10px;

          &:focus {
            border-color: $bg-main1;
          }

          &::placeholder {
            color: $text-main-blur;
          }
        }

        .vdp-datepicker__calendar {
          right: auto;
          left: 0%;
          top: 140%;
          border-radius: 5px;
          border: none;
          filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.15));
          width: 290px;
          height: fit-content;

          &::before {
            position: absolute;
            content: '';
            display: block;
            left: 5%;
            bottom: 100%;
            border: 10px solid transparent;
            border-bottom-color: white;
          }

          div {
            display: grid;
            grid-template-columns: repeat(7, 1fr);
            width: 100%;
            gap: 10px;
            justify-content: center;
            align-content: center;
            align-items: center;
            padding: 0 20px 20px 20px;
          }

          header {
            height: 15%;

            .up {
              @include text-font($helvetica, $dark-1, $font-root, 500, 25px);
              height: 40px;
              display: flex;
              justify-content: center;
              align-items: center;
            }

            .next::after,
            .prev::after {
              border: none;
              width: 100%;
              height: 100%;
              background-image: url('@/assets/images/icon/next-icon.png');
              background-repeat: no-repeat;
              background-size: 6px 10px;
              background-position: center;
              margin: 0;
            }

            .prev {
              &::after {
                background-image: url('@/assets/images/icon/prev-icon.png');
              }
            }
          }

          .cell {
            @include text-font($helvetica, $text-main2, $font-root, 500, 36px);
            text-align: center;
            border-radius: 2px;
            text-align: center;
            padding: 0px;

            &.disabled {
              color: #ddd;
            }
          }

          .day-header {
            width: fit-content;
            color: $text-main-blur;
          }

          .day {
            width: 24px;
            height: 24px;
            margin: 0 auto;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .day,
          .month,
          .year {
            &:hover:not(.disabled):not(.blank) {
              border: 1px solid $bg-main1;
              border-radius: 40px;
            }
          }

          .month,
          .year {
            margin-left: 2px;
            margin-top: 2px;
            width: calc(33.33% - 2px);
          }

          .selected {
            background-color: $bg-main1;
            border-radius: 40px;
            color: #ffffff;
          }
        }
      }
    }

    .pick_gender_iden {
      height: 40px;
      line-height: 20px;
      padding: 10px 0px;
    }
  }

  .box_add_img_iden {
    display: block;
    width: 100%;

    //margin-bottom: 15px;
    img {
      width: 100%;
      min-height: 200px;
      padding-left: 0px;
    }
  }

  .image-error {
    border: 1px solid $color-alizarin-crimson;
  }

  .button_add_file_iden {
    position: absolute;
    @include btn-common(outlined, sm);
    width: auto;
    top: calc(50% + 16px);
    left: 50%;
    transform: translate(-50%, -50%);
    white-space: nowrap;

    @include mobile {
      top: unset;
      bottom: 40px;
    }

    input {
      position: absolute;
      opacity: 0;
      display: block;
      width: 100%;
      height: 100%;
      cursor: pointer;
      top: 0px;
      left: 0px;
    }
  }

  .example {
    max-width: 100%;

    img {
      max-width: 355px;
    }
  }

  .other-info {
    list-style-type: disc;
    font-size: $font-small;
    color: $color-grey-dusty;
    line-height: 20px;
    padding-left: 20px;

    li {
      position: relative;
      margin-bottom: 5px;
      padding-left: 10px;
    }
  }

  .button_submit_iden {
    height: 40px;
    padding: 8px 10px 7px 10px;
    width: 160px;
    overflow: hidden;
    text-transform: uppercase;
    cursor: pointer;
    border-radius: 3px;
    background-color: $color-jungle-green;
    font-family: $font-family-roboto;
    font-size: $font-smaller;
    font-weight: bold;
    letter-spacing: 0.9px;
    color: $color-white;
    margin-left: 10px;

    &:hover,
    &:active,
    &:focus {
      background-color: $color-blue-custom;
    }
  }

  @include mobile {
    padding: 15px;

    :deep {
      .button_drop_search {
        padding: 11px 16px;

        span {
          max-width: 100%;
        }
      }
    }

    .group-info {
      gap: 15px;
    }

    .group_iden {
      + .group_iden {
        margin-top: 32px;
      }

      .group_input {
        margin: 0;
      }
    }
  }
}

.video-modal {
  position: relative;
  padding: 40px 50px 30px 50px;
  border-radius: 10px;
  margin: 0 auto;
  display: block;
  width: 677.39px;
  max-width: calc(100vw - 32px);
  // height: 568px;
  background: white;
  position: relative;

  @include mobile {
    padding: 20px 16px;
  }

  .modal-header {
    position: absolute;
    border: 0;
    top: 0;
    right: 0;

    .close {
      opacity: 1;
      z-index: 100;
      position: relative;
      outline: none;
      background: transparent !important;

      @include mobile {
        margin-top: -8px;
        margin-right: -10px;
      }

      &:hover {
        opacity: 1;
      }

      .icon-close {
        border-radius: 50%;
        line-height: 32px;
        overflow: hidden;
        text-align: center;
        display: inline-block;
        font-size: $font-smaller;
        width: 30px;
        height: 30px;
        margin: 0px 0px 0 0;
        cursor: pointer;
        padding-left: 2px;

        &:hover {
          background-color: $color-light-grey;
          color: $color-grey;
        }
      }
    }
  }

  .time-record {
    text-align: center;
    color: $color-red;
    margin-bottom: 16px;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }

  .video-wrapper {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    img,
    video {
      width: 100%;
      height: auto;
      border-radius: 5px;
      overflow: hidden;
    }

    .count-down {
      position: absolute;
      background: transparent;
      top: 35%;
      width: 100%;
      text-align: center;
      color: white;
      font-size: 100px;
    }
  }

  .actions {
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;

    @include mobile {
      margin-top: 26px;
    }

    .btn-record {
      background: transparent;
    }

    .btn-retake,
    .btn-take {
      @include btn-common(outlined, md);
      width: auto;
      background: transparent;
      height: 42px;
    }

    .btn-save {
      @include btn-common(contained, md);
      width: auto;
    }
  }
}

@include tablet {
  #identity {
    width: 100%;

    #identityForm {
      .group_iden {
        .group_input {
          gap: 0 15px;

          .identity-form-group {
            display: flex;
            flex-direction: column;
          }
        }
      }

      .group-info {
        .mid_iden {
          .box_add_img_iden {
            padding: 24px;
            height: auto;
          }
        }
      }
    }
  }
}

@include mobile {
  #identity {
    padding: 16px 16px 60px;

    #identityForm {
      padding: 16px;

      .digisign-status {
        flex-direction: column;
        align-items: flex-start;

        .country-select-logo {
          margin-top: 4px;
        }
      }

      .kyc-notice {
        :deep {
          strong {
            font-size: 12px;
            line-height: 16px;
          }

          ul li {
            font-size: 12px;
            line-height: 16px;
          }
        }
      }

      .identity-col-4,
      .identity-col-2 {
        grid-template-columns: repeat(1, 1fr);
        gap: 16px;
      }

      .identity-form-label {
        font-size: 14px;
        line-height: 24px;
      }

      .left_content_iden_title {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 16px;
      }

      .group-info .mid_iden {
        flex-direction: column;
        align-items: normal;
        gap: 12px;

        .box {
          width: 100%;
        }

        .description {
          width: 100%;

          p {
            font-size: 12px;
            line-height: 16px;
            margin-bottom: 16px;
            margin-top: 0;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}

:deep .description {
  ul {
    margin-top: 8px;
  }

  > div,
  ul li {
    margin-top: 8px;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 22px;

    @include mobile {
      font-size: 12px;
      line-height: 16px;
    }
  }
}

.margin_330 {
  margin-left: 330px;
}

.color_back {
  color: $color_black !important;
}

.color_gray_dark {
  color: $color-grey-dark;
}

.mgb30 {
  margin-bottom: 30px;
}

.mgb20 {
  margin-bottom: 20px;
}

.mgb15 {
  margin-bottom: 15px;
}

.mgb10 {
  margin-bottom: 10px;
}

.mgt10 {
  margin-top: 10px;
}

.lh25 {
  line-height: 25px;
}

.lh20 {
  line-height: 20px;
}

.divh50 {
  display: block;
  width: 100%;
  height: 50px;
}

.divh35 {
  display: block;
  width: 100%;
  height: 35px;
}

.divh25 {
  display: block;
  width: 100%;
  height: 25px;
}

.divh10 {
  display: block;
  width: 100%;
  height: 10px;
}

.line_iden {
  display: block;
  width: 100%;
  border-top: 1px solid $color-alto;
}

.mgr15 {
  margin-right: 15px;
}

.margin-left-10 {
  margin-left: 10px;
}

select.custom {
  width: 100%;
  height: 42px;
  line-height: 20px;
  padding: 11px 20px;
  font-size: 14px;
  border: 1px solid #cfcfcf;
  border-radius: 3px;
  background: url('@/assets/images/select-arrow.png') no-repeat right;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  background-position-x: 98%;

  &:hover,
  &:active,
  &:focus {
    border-color: $color-green;
  }
}

select.custom::-ms-expand {
  display: none;
}

.preview_0 {
  padding-left: 1px;
}

.left_iden_0 {
  width: 100% !important;
  display: flex !important;
  justify-content: space-between;

  .identity-form-group-0 {
    width: calc(100% - 50% - 40px);
  }

  .identity-form-group-1 {
    width: calc(100% - 50% - 40px);
  }
}

.right_iden_0 {
  width: 100% !important;
  display: flex !important;
  justify-content: space-between;
  margin-left: 0px !important;

  .identity-form-group-0 {
    width: calc(100% - 50% - 40px);
  }

  .identity-form-group-1 {
    width: calc(100% - 50% - 40px);
  }
}

.is-error-image {
  width: 100%;
}

.mgb15 {
  margin-top: 12px;
}

.f-14 {
  font-size: $font-root;
}

.chrome_only:focus {
  outline: -webkit-focus-ring-color auto 5px $color-green !important;
  outline-offset: 1px !important;
}

.player-image {
  display: flex;
  justify-content: center;
}

.btn-download {
  background: $bg-main6;
  color: white;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  border-radius: 60px;
  display: inline-block;
  width: max-content;
  padding: 9px 18px;
  margin-bottom: 24px;

  &.download-kyc-doc {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-right: 1px solid #fff;
  }
  &.download-kyc-vid {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  &:hover,
  &:active,
  &:focus {
    background: #ddd;
    color: $text-main;
  }

  @include tablet-d {
    font-size: 14px;
    padding: 9px 12px;
  }
}
</style>
